<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect';

import UserService from "@/services/user";

export default {
  page: {
    title: "Oficinas",
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Oficinas",
      hirers: [],
      hirerSelected: {},

      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      showMoreFilters: true,
      searchValue: '',
      searchCity: '',
      searchState: '',

      states: [],
    }
  },
  computed: {
    rows() {
      return this.hirers.length;
    }
  },
  methods: {
    loadHirers(page = 1) {
      const loader = this.$loading.show();

      const params = this.getSearchParams();

      UserService.getHirers(page, params).then(res => {
        this.hirers = res.data.hirers;

        this.count = res.data.count
        this.currentCount = res.data.currentCount
        this.currentPage = res.data.currentPage
        this.totalPages = res.data.totalPages
        this.perPage = res.data.perPage
        this.startIndex = res.data.startIndex
        this.endIndex = res.data.endIndex

        loader.hide();
      }).catch(() => {
        loader.hide();
      })
    },
    onPageChange() {
      this.loadHirers(this.currentPage);
    },
    search() {
      this.loadHirers();
    },
    clearFilters() {
      this.searchValue = '';
      this.searchCity = '';
      this.searchState = '';
    },
    exportData() {
      const loader = this.$loading.show();

      const params = this.getSearchParams();
      params.export = true;

      UserService.getHirers(null, params).then(res => {
        loader.hide();

        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'lista-oficinas.csv');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        loader.hide();
      })
    },
    getSearchParams() {
      const params = {};

      if (this.searchValue) {
        params.term = this.searchValue;
      }

      if (this.searchCity) {
        params.city = this.searchCity;
      }

      if (this.searchState) {
        params.state = this.searchState;
      }

      return params;
    },
    changePasswordModal(hirer) {
      this.hirerSelected = hirer;
      this.hirerSelected.newPassword = '';
      this.$bvModal.show('modal-change-password');
    },
    changePassword() {
      const loader = this.$loading.show();

      UserService.changePassword(this.hirerSelected._id, this.hirerSelected.newPassword).then(() => {
        loader.hide();

        this.$toast.open('Senha alterada');
        this.$bvModal.hide('modal-change-password');  
      }).catch(() => {
        loader.hide();

        this.$toast.error('Não foi possível alterar a senha');
      })
    }
  },
  mounted() {
    this.loadHirers();

    this.states = ['', 'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col">
        <div class="card mb-0">
          <div class="card-body">
            <form @submit.prevent="search" class="align-items-center direction-column">
              <div class="row">
                <div class="col-8">
                  <input type="text" class="form-control" v-model="searchValue" placeholder="Pesquisar por nome ou CNPJ..." />
                </div>
                <div class="col-4 search-users">
                  <button type="submit" class="btn btn-rounded btn-primary button-large">
                    Pesquisar
                  </button>

                  <button class="btn btn-rounded btn-outline-primary ml-2" @click="exportData">
                    Exportar CSV
                  </button>
                </div>
              </div>

              <div class="row" v-if="showMoreFilters">
                <div class="col-8">
                  <div class="row mt-3">
                    <div class="col col-5">
                      <label class="mb-1">Cidade</label>
                      <input type="text" class="form-control" v-model="searchCity" />
                    </div>

                    <div class="col col-3">
                      <label class="mb-1">UF</label>
                      <multiselect
                        v-model="searchState"
                        :options="states"
                        placeholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                        :close-on-select="true"
                        :searchable="true"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="row mt-3 mb-0">
                    <b-button variant="link" @click="clearFilters" class="mr-2">Limpar Filtros</b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class>
          <div class="table-responsive">
            <table class="table project-list-table table-centered table-borderless">
              <thead>
                <tr>               
                  <th scope="col">Nome</th>
                  <th scope="col">CNPJ</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">Cidade/UF</th>
                  <th scope="col">Data do Cadastro</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="hirer in hirers" :key="hirer._id">               
                  <td>
                    <div class="team">
                      <a href="javascript: void(0);" class="team-member d-inline-block" v-if="hirer.picture">
                        <img :src="hirer.picture" class="rounded-circle avatar-xs m-1 mr-2" alt />
                      </a>
                      <div class="rounded-circle avatar-xs m-1 mr-2 avatar-letter" v-if="!hirer.picture">{{hirer.name.toUpperCase().substring(0,1)}}</div>
                      {{hirer.name}}
                    </div>
                  </td>
                  <td>{{hirer.cnpj}}</td>
                  <td>{{hirer.phoneNumber}}</td>
                  <td>{{hirer.cityDescription}}</td>
                  <td>{{new Date(hirer.createdAt) | dateFormat('DD/MM/YYYY HH:mm')}}</td>
                  <td>
                    <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>

                      <router-link tag="a" :to="'/hirer/' + hirer._id">
                        <b-dropdown-item href="javascript: void(0);">
                          <i class="fas fa-edit text-primary mr-2 mt-2"></i>
                          Editar
                        </b-dropdown-item>
                      </router-link>

                      <b-dropdown-item @click="changePasswordModal(hirer)" href="javascript: void(0);">
                        <i class="fas fa-key text-secondary mr-2 mt-2"></i>
                        Alterar Senha
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-between align-items-md-center">
      <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>

      <div class="col-xl-5">
        <div class="text-md-right float-xl-right mt-2 pagination-rounded">
          <b-pagination
            v-model="currentPage" 
            :total-rows="count"
            :per-page="perPage"
            @input="onPageChange"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-change-password"
      title="Alterar Senha"
      title-class="font-18"
      size="sm"
      centered
    >
      <p class="m-0"><b>Oficina:</b> {{hirerSelected.name}}</p>
      <p><b>CNPJ:</b> {{hirerSelected.cnpj}}</p>

      <div class="row mb-3">
        <div class="col col-12">
          <label class="mb-1">Nova Senha</label>
          <input type="password" class="form-control" v-model="hirerSelected.newPassword" />
        </div>
      </div>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-change-password')" class="mr-3">Cancelar</b-btn>
        <b-btn variant="primary" @click="changePassword">Confirmar Alteração</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .team {
    display: flex;
    align-items: center;    
  }

  .avatar-letter {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    background-color: rgb(241, 101, 73, 0.3);
    color: #F16549;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
  }

  .search-users {
    display: flex;

    .button-large {
      flex-grow: 1;
    }
  }

  .more-filters .multiselect__tags {
    border-radius: 30px;
  }

  .form-control {
    height: 38px;
  }
</style>